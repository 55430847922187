export const BG_PRIMARY = '#F3F2F2';
export const BG_SECONDARY = '#304c62';
export const BG_SECONDARY_LIGHT = '#7b8c9a';
export const BG_SECONDARY_DARK = '#86550a';
export const BG_SECONDARY_TRANSPARENT = 'rgb(48 76 98 / 40%)';
// this colors used for green background
export const BG_ALTERNATIVE = '#304c62';
export const TEXT_PRIMARY_LIGHT = '#cf9c2f';
export const TEXT_PRIMARY = '#304c62';
export const TEXT_PRIMARY_DARK = '#000';
export const TEXT_SECONDARY = '#fff';

// Exclusive to template-naomirezky
export const FLOWER_COLOR = '#304c62';